<template>
  <div class="bg-img-footer overflow-y-hidden">
    <div class="container">
    <div class="footer">
      <v-row class="text-wrap">
        <v-col cols="12" md="6" >
          <img src="~/assets/images/footer/logo.svg" alt="" class="logo-icon">
          <p class="mt-10">انضم إلى قائمتنا البريدية مجانا واحصل على آخر العروض وأحدث المقالات والأخبار</p>
          <div class="d-flex mt-5">
            <v-btn @click="handleSubscribeToEmail" size="large" :loading="authStore.loading" elevation="0" class="email-btn rounded-be-pill rounded-te-pill text-no-wrap">
              سجل في النشرة البريدية
            </v-btn>
            <input v-model="email" type="email" placeholder="البريد اﻹلكتروني" class="email-input"/>
          </div>
          <div v-if="!smAndDown" class="d-flex justify-space-between mt-5">
            <div>
            <img src="~/assets/images/footer/google-play-badge-logo-svgrepo-com.svg" alt="" class="ml-3">
            <img src="~/assets/images/footer/download-on-the-app-store-apple-logo-svgrepo-com.svg" alt="">
            </div>
            <div> 
              <a href="https://www.facebook.com/profile.php?id=100074687768689" target="_blank" class="d-inline-block">
              <img src="~/assets/images/icons/footer/facebook.svg" alt="" class="mr-2" :class="{'d-none': hoverFacebook}" @mouseover="hoverFacebook =true">
              <img src="~/assets/images/icons/footer/facebook-dark.svg" alt="" class="mr-2" :class="{'d-none': !hoverFacebook}" @mouseleave="hoverFacebook = false">
              </a>
              <a href="https://x.com/calwe_app" target="_blank" class="d-inline-block">
              <img src="~/assets/images/icons/footer/x.svg" alt="" class="mr-2" :class="{'d-none': hoverX}" @mouseover="hoverX =true">
              <img src="~/assets/images/icons/footer/x-dark.svg" alt="" class="mr-2" :class="{'d-none': !hoverX}" @mouseleave="hoverX = false">
              </a>
              <a href="https://www.instagram.com/calwe_app" target="_blank" class="d-inline-block">
              <img src="~/assets/images/icons/footer/instagram.svg" alt="" class="mr-2" :class="{'d-none': hoverInstagram}" @mouseover="hoverInstagram =true">
              <img src="~/assets/images/icons/footer/instagram-dark.svg" alt="" class="mr-2" :class="{'d-none': !hoverInstagram}" @mouseleave="hoverInstagram = false">
              </a>
            </div>
          </div>
          <div v-else class="d-flex mt-5"> 
              <a href="https://www.facebook.com/profile.php?id=100074687768689" target="_blank" class="d-inline-block">
              <img src="~/assets/images/icons/footer/facebook.svg" alt="" class="mr-2" :class="{'d-none': hoverFacebook}" @mouseover="hoverFacebook =true">
              <img src="~/assets/images/icons/footer/facebook-dark.svg" alt="" class="mr-2" :class="{'d-none': !hoverFacebook}" @mouseleave="hoverFacebook = false">
              </a>
              <a href="https://x.com/calwe_app" target="_blank" class="d-inline-block">
              <img src="~/assets/images/icons/footer/x.svg" alt="" class="mr-2" :class="{'d-none': hoverX}" @mouseover="hoverX =true">
              <img src="~/assets/images/icons/footer/x-dark.svg" alt="" class="mr-2" :class="{'d-none': !hoverX}" @mouseleave="hoverX = false">
              </a>
              <a href="https://www.instagram.com/calwe_app" target="_blank" class="d-inline-block">
              <img src="~/assets/images/icons/footer/instagram.svg" alt="" class="mr-2" :class="{'d-none': hoverInstagram}" @mouseover="hoverInstagram =true">
              <img src="~/assets/images/icons/footer/instagram-dark.svg" alt="" class="mr-2" :class="{'d-none': !hoverInstagram}" @mouseleave="hoverInstagram = false">
              </a>
            </div>
          <section class="mt-2">
            <p class="text-xs text-black-color">وسائل الدفع المتاحة</p>
            <div class="payment-section rounded-xl py-2 px-4 d-flex align-center gap-2 mt-1">
              <img src="~/assets/images/shared/master-card-1-1.svg" alt="" class="mx-1">
              <img src="~/assets/images/shared/visa.svg" alt="" class="mx-1">
              <img src="~/assets/images/icons/shared/applye-pay.svg" alt="" class="mx-1">

            </div>
          </section>
        </v-col>
        <v-col cols="6" md="2" lg="2" xl="2">
          <h3 class="text-sm text-black-color">روابط هامة</h3>

          <NuxtLink to="/mentor/details">
            <div class="my-4">
              قائمة المرشدين
            </div>
          </NuxtLink>

          <NuxtLink :to="authStore.coachUrl" target="blank">
            <div class="my-4">
              إنضم كمرشد
            </div>
          </NuxtLink>

          <NuxtLink to="/contact-us">
            <div class="my-4">
              تواصل معنا
            </div>
          </NuxtLink>

          <NuxtLink to="/contact-us">
            <div class="my-4">
              اﻷسئلة الشائعة
            </div>
          </NuxtLink>
          <p v-if="lgAndUp" class="text-xs text-center mt-130 text-no-wrap text-black-color">جميع الحقوق محفوظة لشركة إلياك ©2024</p>

        </v-col>

        <v-col cols="6" md="2" lg="2" xl="2">
          <h3 class="text-sm text-black-color">إعرف أكثر</h3>
          <NuxtLink to="/termsAndConditions">
            <div class="my-4">
              الشروط والأحكام
            </div>
          </NuxtLink>

          <NuxtLink to="/termsAndConditions">
            <div class="my-4">
              سياسة الخصوصية
            </div>
          </NuxtLink>
        </v-col>

        <v-col v-if="lgAndUp" cols="3">
          <img src="~/assets/images/footer/mobile.svg" alt="">
        </v-col>
      </v-row>
      <p v-if="mdAndDown" class="text-xs text-center my-5 text-no-wrap text-black-color">جميع الحقوق محفوظة لشركة إلياك ©2024</p>
    
    </div>
  </div>
  </div>
  <SharedResponseAlert :response="response" />

</template>

<script setup>
import { useAuthStore } from '~~/stores/AuthStore';
import { useDisplay } from 'vuetify';
const { lgAndUp, mdAndDown, smAndDown } = useDisplay();
const authStore = useAuthStore();

const hoverFacebook = ref(false);
const hoverInstagram = ref(false);
const hoverX = ref(false);

const email = ref('');

function handleSubscribeToEmail() {
  if (email.value) {
    authStore.subscribeToEmail(email.value).then(res => {
      if (res) {
        email.value = '';
      }
    })
  }
}

const response = computed(() => {
  return authStore.responseData;
});
</script>

<style scoped lang="scss">
.footer {
  margin: 4rem 3rem 0;
}
.footer > a:hoverFacebook{
  color: #fff !important;
}
.payment-section {
  background-color: rgba(97, 170, 167, 0.3);
  width: fit-content;
  img{
    width: 50px;
  }
}
@media (max-width:600px) {
    .footer{
      margin: 4rem 1rem 0;
    }
  }
</style>
